<template>
  <div :class="classList">
    <button class="mobile-cart-toggler" @click="mobileIsOpen = !mobileIsOpen"></button>
    <div v-if="items.length" class="cart-wrapper cart-not-empty pt-3 pt-md-0">
        <h4>{{domainsCount}} Nom de domaine</h4>
        <div class="cart-items mb-4">
            <div v-for="(cartItem, cartItemKey) in items" :key="cartItemKey" class="mb-1 cart-item">
                <div class="cart-item-domain">
                    <span class="title"><b>{{cartItem.domain.dn}}</b> - <span class="text-nowrap">{{cartItem.domain.type == 'register' ? 'Enregistrement' : 'Transfer'}} - {{cartItem.domain.duration + (cartItem.domain.duration > 1 ? ' ans' : ' an')}}</span></span>
                    <span class="price">
                        {{cartItem.domain.pricing.MAD ? cartItem.domain.pricing.MAD * cartItem.domain.duration + ' DH' : 'Gratuit'}}
                        <button class="btn btn-remove-item" @click="$emit('remove-from-cart', {key: cartItemKey, type: 'domain'})"></button>
                    </span>
                </div>
                <div v-if="cartItem.domain.idprotect" class="cart-item-privacy">
                    <span class="title">Protection d’identité - {{cartItem.domain.duration + (cartItem.domain.duration > 1 ? ' ans' : ' an')}}</span>
                    <span class="price">
                        {{$parent.idProtectionPrice * cartItem.domain.duration}} DH
                        <button class="btn btn-remove-item" @click="$emit('remove-from-cart', {key: cartItemKey, type: 'privacy'})"></button>
                    </span>
                </div>
            </div>
        </div>
        <h4 v-if="hostingsCount">{{hostingsCount}} Hébergement</h4>
        <div class="cart-items mb-4" v-if="hostingsCount">
            <template v-for="(cartItem, cartItemKey) in items">
                <div v-if="cartItem.hosting" class="mb-1 cart-item" :key="cartItemKey">
                    <div class="cart-item-hosting">
                        <div class="title">Hebergement - <span class="text-nowrap">{{cartItem.hosting.title}} - {{cartItem.hosting.plan_duration + (cartItem.hosting.plan_duration > 1 ? ' ans' : ' an')}}</span></div>
                        <div class="price">
                            {{cartItem.hosting.price.MAD ? cartItem.hosting.price.MAD + ' DH' : 'Gratuit'}}
                            <button class="btn btn-remove-item" @click="$emit('remove-from-cart', {key: cartItemKey, type: 'hosting'})"></button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="separator"></div>
        <div class="row align-items-center">
            <div class="col-md-12 mb-2">
                <div class="total-amount">
                    <label for="">Sous-total</label>
                    <div class="">{{cartTotal}} DH</div>
                </div>
            </div>
            <div class="col-md-12 mb-2">
                <div class="total-amount">
                    <label for="">TVA ({{$root.tax}}%)</label>
                    <div class="">{{(cartTotal * 20 / 100).toFixed(2)}} DH</div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="total-amount">
                    <label for="">Total</label>
                    <div class="amount">{{$root.getTaxedPrice(cartTotal)}} DH</div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="actions d-grid mt-md-4">
                    <a :href="nextPage" class="btn btn-primary btn-checkout my-2 my-md-0 me-md-2">Continuer</a>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="cart-wrapper">
        <div class="empty-cart-message">
            Commencez l'ajout d'un nom de domaine en cliquant sur le bouton <b>Enregistrer</b>.
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['items', 'nextPage'],
    computed: {
        cartTotal() {
            let total = 0;
            this.items.forEach(cartItem => {
                if (cartItem.domain.pricing.MAD)
                    total += cartItem.domain.pricing.MAD * cartItem.domain.duration;
                if (cartItem.domain.idprotect)
                    total += this.$parent.idProtectionPrice * cartItem.domain.duration;
                if (cartItem.hosting?.price.MAD)
                    total += cartItem.hosting.price.MAD;
            });
            return total;
        },
        hostings(){
            return this.items.filter(item => item.hosting);
        },
        domainsCount(){
            return this.items.length;
        },
        hostingsCount(){
            return this.hostings.length;
        },
        classList(){
            let classes = "col-md-4 text-white py-3 py-md-5 px-md-4 cart-component";
            if(this.mobileIsOpen)
                classes += " is-open";
            if(!this.items.length)
                classes += " d-none d-md-block";
            return classes;
        }
    },
    data(){
        return {
            mobileIsOpen: false
        }
    }
}
</script>