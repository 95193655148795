<template>
    <div class="footer-cart-component">
        Total <div class="amount">{{$root.getTaxedPrice(cartTotal)}} DH</div>
        <a :href="nextPage" :class="[!$parent.configurationValid ? 'disabled' : '', 'btn btn-primary btn-checkout my-2 my-md-0 me-md-2']" @click="customAction($event)" :disabled="!$parent.configurationValid">Continuer</a>
        <button v-if="cartId" class="btn btn-primary btn-checkout" @click="$emit('new-order')">Passer une nouvelle commande</button>
    </div>
</template>

<script>
export default {
    props: ['items', 'nextPage', 'nextAction', 'cartId'],
    methods: {
        customAction(event){
            if(this.nextAction == 'submitCheckout'){
                event.preventDefault();
                this.$parent.$refs.checkout.submit();
            }
        }
    },
    computed: {
        cartTotal() {
            let total = 0;
            this.items.forEach(cartItem => {
                if (cartItem.domain.pricing.MAD)
                    total += cartItem.domain.pricing.MAD * cartItem.domain.duration;
                if (cartItem.domain.idprotect)
                    total += this.$parent.idProtectionPrice * cartItem.domain.duration;
                if (cartItem.hosting?.price.MAD)
                    total += cartItem.hosting.price.MAD;
            });
            return total;
        }
    }
}
</script>