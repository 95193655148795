require("./bootstrap");

import Vue from "vue";
import TheCart from "./components/TheCart.vue";
import TheFooterCart from "./components/TheFooterCart.vue";
import DomainSuggestion from "./components/DomainSuggestion.vue";
import HostingConfigurationItem from "./components/HostingConfigurationItem.vue";
import StripeCheckoutForm from "./components/StripeCheckoutForm.vue";

const app = new Vue({
    el: "#app",
    components: {
        TheCart: TheCart,
        TheFooterCart: TheFooterCart,
        DomainSuggestion: DomainSuggestion,
        HostingConfigurationItem: HostingConfigurationItem,
        StripeCheckoutForm: StripeCheckoutForm,
    },
    data: {
        step: null,
        domain: "",
        tax: window.tax ?? 0,
        auth: {
            view: window.authView ?? "signup",
            clientType: "particulier",
            step: 1,
        },
        idProtectionPrice: 90,
        lookupType: "register",
        lookupResult: {
            isLoading: false,
            selectedDuration: 1,
            result: null,
        },
        suggestions: {
            isLoading: false,
            result: null,
        },
        availableProducts: {},
        cart: {
            items: [],
            paymentMethod: null,
            editingItem: null,
        },
        contact: {
            id: null,
            type: "default",
            contactType: "particulier",
        },
        dnsConfiguration: {
            nameservers: [null, null, null, null],
            preset: null,
            type: "default",
        },
        nationalId: window.nationalId ?? null,
    },
    methods: {
        domainLookup() {
            // http://creer-order.test/api/lookup?domain=lkjzdljzdizdj
            // http://creer-order.test/api/transfer?domain=lkjzdljzdizdj
            if (this.domain) {
                this.lookupResult.isLoading = true;
                const endpoint =
                    "/api/" +
                    (this.lookupType == "register" ? "lookup" : "transfer");
                // console.log(endpoint);
                axios
                    .get(endpoint, {
                        params: {
                            domain: this.domain,
                        },
                    })
                    .then(
                        function (response) {
                            this.lookupResult.isLoading = false;
                            this.lookupResult.result = response.data;
                            this.lookupResult.result.isInCart =
                                this.cart.items.find(
                                    (element) =>
                                        element.domain.dn ==
                                        this.lookupResult.result.sld +
                                            "." +
                                            this.lookupResult.result.tld
                                );
                            if (this.lookupType == "register")
                                this.getSuggestions();
                        }.bind(this)
                    )
                    .catch(function (error) {
                        console.log(error);
                    });
                this.updateStorage();
            }
        },
        getSuggestions() {
            // http://creer-order.test/api/suggestions?sld=testwkjnsjkas&tld=ma&max=10&region=ma
            this.suggestions.isLoading = true;
            axios
                .get("/api/suggestions", {
                    params: {
                        sld: this.lookupResult.result.sld,
                        tld: this.lookupResult.result.tld,
                        max: 10,
                        region: "world",
                    },
                })
                .then(
                    function (response) {
                        this.suggestions.result = response.data.map(
                            (suggestion) => {
                                suggestion.isInCart = this.cart.items.find(
                                    (element) =>
                                        element.domain.dn ==
                                        suggestion.sld + "." + suggestion.tld
                                );
                                return suggestion;
                            }
                        );
                        this.suggestions.isLoading = false;
                    }.bind(this)
                )
                .catch(function (error) {
                    console.log(error);
                });
        },
        updateStorage() {
            let data = {
                domain: this.domain,
                cart: this.cart,
                contact: this.contact,
                dns: this.dnsConfiguration,
            };
            localStorage.setItem("data", JSON.stringify(data));
        },
        addToCart(item) {
            if (item.type === "domain") {
                const itemDomain = item.data.sld + "." + item.data.tld;
                if (
                    !this.cart.items.find(
                        (element) => element.domain.dn == itemDomain
                    )
                ) {
                    const selected = item.data;
                    var cartItem = {
                        domain: {
                            dn: selected.sld + "." + selected.tld,
                            duration: item.duration,
                            durations: selected.durations,
                            idprotect: selected.idprotect,
                            options: {
                                idProtectionAvailability: selected.idprotect,
                            },
                            pricing: selected.pricing,
                            sld: selected.sld,
                            tld: selected.tld,
                            type: selected.type,
                        },
                        hosting: null,
                    };
                    this.cart.items.push(cartItem);
                    this.resetSuggestions();
                }
            }
            if (item.type === "hosting") {
                const plan = this.availableProducts[item.data.planKey];
                this.cart.items[item.data.cartItemKey]["hosting"] = {
                    id: plan.id,
                    title: plan.name,
                    plan_duration: item.duration,
                    cycle: plan.pricings[item.duration - 1].cycle,
                    price: plan.pricings[item.duration - 1],
                    plan: 0,
                    duration: item.duration - 1,
                    category: {},
                    addons: [],
                };
            }
        },
        removeFromCart(item) {
            if (item.type === "domain") this.cart.items.splice(item.key, 1);
            else if (item.type === "privacy")
                this.cart.items[item.key].domain.idprotect = false;
            else this.cart.items[item.key].hosting = null;
            if (
                this.cart.items.length == 0 &&
                !window.location.pathname.includes("search") &&
                !window.location.pathname.includes("success")
            )
                window.location.replace("/");
            this.resetSuggestions();
        },
        resetSuggestions() {
            if (this.suggestions.result) {
                this.suggestions.result = this.suggestions.result.map(
                    (suggestion) => {
                        suggestion.isInCart = this.cart.items.find(
                            (element) =>
                                element.domain.dn ==
                                suggestion.sld + "." + suggestion.tld
                        );
                        return suggestion;
                    }
                );
            }
            if (this.lookupResult.result) {
                this.lookupResult.result.isInCart = this.cart.items.find(
                    (element) =>
                        element.domain.dn ==
                        this.lookupResult.result.sld +
                            "." +
                            this.lookupResult.result.tld
                );
            }
        },
        updateCartItem(item) {
            //TODO: rename to reload
            if (item.type === "domain") {
                if (item.data.isInCart) {
                    const itemDomain = item.data.sld + "." + item.data.tld;
                    this.cart.items.forEach(function (cartItem) {
                        if (cartItem.domain.dn == itemDomain) {
                            const selected = item.data;
                            cartItem.domain.duration = item.duration;
                        }
                    });
                }
            }
        },
        editCartItem(element) {
            this.cart.editingItem = element.item;
            window.location.replace(element.url + "#item_" + element.key);
        },
        resetOrder() {
            this.domain = "";
            this.cart = {
                items: [],
                paymentMethod: null,
                editingItem: null,
            };
            this.contact = {
                id: null,
                type: "default",
                contactType: "particulier",
            };
            this.dnsConfiguration = {
                nameservers: [null, null, null, null],
                preset: null,
                type: "default",
            };
        },
        newOrder() {
            this.resetOrder();
            this.$refs.forget.submit();
        },
        validateConfiguration() {
            let errors = [];
            if (
                this.contact.type != "default" &&
                this.contact.type != "existing"
            )
                errors.push(
                    "Merci de choisir un contact existant ou le contact principal."
                );
            if (
                this.dnsConfiguration.type == "custom" &&
                !(
                    this.dnsConfiguration.nameservers[0] &&
                    this.dnsConfiguration.nameservers[1]
                )
            )
                errors.push("Merci de rentrer vos nameserveurs.");
            if (
                this.dnsConfiguration.type == "preset" &&
                !this.dnsConfiguration.preset
            )
                errors.push("Merci de choisir votre platforme.");
            return errors;
        },
        navigateTo(page, step, event) {
            if (page === "auth") {
                let currentStepWrapper = event.target.closest(".auth-step");
                if (step === 2) {
                    let valid = true;
                    currentStepWrapper
                        .querySelectorAll(".is-required")
                        .forEach((element) => {
                            if (!element.value) {
                                valid = false;
                                element.classList.add("is-invalid");
                            }
                        });
                    if (
                        currentStepWrapper.querySelector("#inputPassword")
                            .value !=
                        currentStepWrapper.querySelector(
                            "#inputPasswordConfirmation"
                        ).value
                    ) {
                        valid = false;
                        currentStepWrapper
                            .querySelector("#inputPassword")
                            .classList.add("is-invalid");
                        currentStepWrapper
                            .querySelector("#inputPasswordConfirmation")
                            .classList.add("is-invalid");
                    }
                    if (valid) this.auth.step = step;
                }
            }
        },
        getTaxedPrice(price) {
            return (price + (price * this.tax) / 100).toFixed(2);
        },
        // method to check if cart has a domain with .ma tld
        hasMaDomain() {
            return this.cart.items.some((item) => item.domain.tld == "ma");
        },
    },
    computed: {
        configurationValid() {
            if (
                (this.contact.type != "default" &&
                    this.contact.type != "existing") ||
                (this.dnsConfiguration.type == "custom" &&
                    !(
                        this.dnsConfiguration.nameservers[0] &&
                        this.dnsConfiguration.nameservers[1]
                    )) ||
                (this.dnsConfiguration.type == "preset" &&
                    !this.dnsConfiguration.preset)
            )
                return false;

            if (
                this.hasMaDomain() &&
                !this.nationalId &&
                window.location.pathname.includes("config")
            )
                return false;
            return true;
        },
    },
    mounted() {
        var storedData = localStorage.getItem("data");
        if (storedData) {
            let parsed = JSON.parse(storedData);
            this.domain = parsed.domain;
            this.cart = parsed.cart;
            this.contact = parsed.contact;
            this.dnsConfiguration = parsed.dns;
        }
        if(this.hasMaDomain() && !this.nationalId && window.location.pathname.includes("payment"))
            window.location.replace("/config");
        axios
            .get("/api/hostings")
            .then(
                function (response) {
                    this.availableProducts = response.data;
                }.bind(this)
            )
            .catch(function (error) {
                console.log(error);
            });
        if (
            this.cart.items.length == 0 &&
            !window.location.pathname.includes("search") &&
            !window.location.pathname.includes("success")
        )
            window.location.replace("/");
        if (
            window.location.pathname.includes("search") &&
            window.location.search
        ) {
            var searchParams = new URLSearchParams(window.location.search);
            this.domain = searchParams.get("q");
            this.domainLookup();
        }
        if (this.cart.editingItem && !window.location.hash) {
            this.cart.editingItem = null;
        }
    },
    watch: {
        cart: {
            handler() {
                this.updateStorage();
            },
            deep: true,
        },
        dnsConfiguration: {
            handler() {
                this.updateStorage();
            },
            deep: true,
        },
        contact: {
            handler() {
                this.updateStorage();
            },
            deep: true,
        },
    },
});
