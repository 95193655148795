<template>
  <div :class="[beingEdited ? 'highlighted' : '', 'hosting-item-component']">
    <div class="domain-block mt-3">
        <div class="domain-data">
            <div class="domain-name">
                {{cartItem.domain.sld}}<b>.{{cartItem.domain.tld}}</b>
            </div>
            <div class="domain-price">
                <select v-model="cartItem.domain.duration" class="duration form-select">
                    <option :value="duration" v-for="(duration, key) in cartItem.domain.durations" :key="key">{{duration + (duration > 1 ? ' ans' : ' an')}} - {{ cartItem.domain.pricing.MAD ? cartItem.domain.pricing.MAD * duration + ' DH' : 'Gratuit' }}</option>
                </select>
            </div>
        </div>

        <div class="epp-box-wrapper" v-if="cartItem.domain.type == 'transfer'">
        <div class="separator"></div>
            <div class="epp-box">
                Saisissez le code d’autorisation de transfert (code EPP).
                <div class="epp-input-wrapper">
                    <input type="text" name="epp" class="form-control" placeholder="Code EPP" v-model="cartItem.domain.epp">
                </div>
                <div class="epp-tip">Si vous ne l’avez pas, vous pouvez continuer et nous l’envoyer après validation de votre commande.</div>
            </div>
        </div>

        <div class="domain-privacy-wrapper" v-if="cartItem.domain.options.idProtectionAvailability">
            <div class="separator"></div>
            <div class="privacy-form-switch form-switch">
                <label class="form-check-label" :for="'privacySwitch_' + cartItemKey">Protégez votre identité en ligne en cachant vos données personnelles</label>
                <input class="form-check-input" type="checkbox" :id="'privacySwitch_' + cartItemKey" v-model="cartItem.domain.idprotect">
            </div>
            <div class="alert alert-warning d-flex mt-4" role="alert" v-if="!cartItem.domain.idprotect">
                <div class="alert-icon"></div>
                <div class="alert-text">
                    En désactivant la protection d’identité, votre nom complet, numéro de téléphone, adresse et e-mail seront visibles au public et accessibles sur les sites de recherche Whois.
                </div>
            </div>
        </div>
    </div>
    <div class="hosting-block">
        <div class="hosting-block-title">Ajouter un hébergement</div>
        <div class="hosting-features">
            <div class="feature feature-ssl">Certificat SSL à vie inclus gratuitement afin de garantir la sécurité de votre site.</div>
            <div class="feature feature-support">Support par email et téléphone 24h/7j inclus.</div>
            <div class="feature feature-backup">Backup à vie inclus gratuitement pour restaurer vos données rapidement et à tout moment.</div>
        </div>
        <div class="d-grid mt-4">
            <a class="btn btn-warning" @click="displayHosting = !displayHosting"><i :class="[(displayHosting || beingEdited || cartItem.hosting) ? 'bi-chevron-up' : 'bi-chevron-down', 'bi']"></i> Ajouter un hébergement</a>
        </div>
        <div class="hosting-plans-wrapper" v-if="displayHosting || beingEdited || cartItem.hosting">
            <hosting-configuration-item-plan v-for="(plan, planKey) in this.$parent.availableProducts" :selected-plan="cartItem.hosting" :plan="plan" :plan-key="planKey" :cart-item-key="cartItemKey" :key="planKey" v-on="$listeners"></hosting-configuration-item-plan>
        </div>
    </div>
</div>
</template>

<script>
import HostingConfigurationItemPlan from "./HostingConfigurationItemPlan.vue";

export default {
    props:['cartItem', 'cartItemKey', 'editingItem'],
    components: {
        HostingConfigurationItemPlan: HostingConfigurationItemPlan
    },
    data(){
        return {
            displayHosting: false
        }
    },
    computed:{
        beingEdited(){
            return this.cartItem.domain.dn == this.editingItem?.domain.dn;
        }
    }
}
</script>