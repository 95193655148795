<template>
    <div :class="[isActivePlan(plan.id) ? 'active' : '', 'hosting-item-plan-component']">
        <h4 class="plan-title">{{plan.name}}</h4>
        <div class="plan-price">{{plan.pricings[0]['MAD']}} <small>DH HT/an</small></div>
        <div class="plan-slogan">{{plan.specs.title}}</div>
        <slot></slot>
        <div class="plan-specs-wrapper" v-html="plan.specs.features"></div>
        <div :class="[isActiveDuration(price.duration) && isActivePlan(plan.id) ? 'active' : '', 'btn-select-hosting']" v-for="(price, priceKey) in plan.pricings" :key="priceKey" @click="$emit('add-to-cart', {duration: price.duration, type:'hosting', data: {planKey: planKey, cartItemKey: cartItemKey}})">
            <div class="duration">{{price.duration + (price.duration > 1 ? ' ans' : ' an')}}</div>
            <div class="amount">{{price.MAD}} DH</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['plan', 'planKey', 'cartItemKey', 'selectedPlan'],
    methods: {
        isActivePlan(planId){
            return this.selectedPlan?.id == planId;
        },
        isActiveDuration(duration){
            return this.selectedPlan?.plan_duration == duration;
        },
    }
}
</script>