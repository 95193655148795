<template>
    <div :class="[!suggestion.available ? 'unavailable' : '', 'lookup-result-item']">
        <div class="row align-items-center">
            <div class="domain-wrapper col-12 col-md-6">
                <div class="domain-name">
                    <span>{{suggestion.sld}}</span>.<b>{{suggestion.tld}}</b>
                </div>
            </div>
            <div class="col-12 col-md-3 actions">
                <select name="duration" v-if="suggestion.available" v-model="selectedDuration" class="duration form-select" id="" @change="$emit('update-cart-item', {duration: selectedDuration, type:'domain', data: suggestion})">
                    <option :value="duration" v-for="(duration, key) in suggestion.durations" :key="key">{{duration + (duration > 1 ? ' ans' : ' an')}} - {{ suggestion.pricing.MAD * duration }} DH</option>
                </select>
                <div class="d-none d-md-block domain-unavailable me-4" v-else>Indisponible</div>
            </div>
            <div class="col-12 col-md-3 mt-2 d-grid">
                <button :class="[suggestion.isInCart ? 'disabled in-cart' : '', 'btn btn-add-to-cart']" v-if="suggestion.available" @click="$emit('add-to-cart', {duration: selectedDuration, type:'domain', data: suggestion})">{{ suggestion.isInCart ? 'Ajouté' : 'Enregistrer' }}</button>
                <button class="btn btn-add-to-cart disabled unavailable" disabled="disabled" v-else>Indisponible</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['suggestion'],
    data: function(){
        return {
            selectedDuration: 1
        }
    }
}
</script>