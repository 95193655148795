<template>
  <div id="stripe-checkout-form">
      <form id="payment-form" @submit.prevent="processPayment">
        
        <!-- We'll put the error messages in this element -->
        <div id="card-errors" v-if="cardErrors" class="alert alert-danger" role="alert">{{cardErrors}}</div>

        <div ref="cardElement">
            <!-- Elements will create input elements here -->
        </div>
        
        <div class="actions d-grid mt-4">
            <button class="btn btn-primary btn-checkout" id="card-button" :class="[loading ? 'disabled' : '', 'btn btn-primary btn-checkout']" :disabled="loading">
                <div v-if="loading" class="spinner-grow" role="status">
                    <span class="visually-hidden">Chargement...</span>
                </div>
                <span v-else>Continuer</span>
            </button>
        </div>
    </form>
    <form :action="url" method="POST" ref="stripeRef">
        <input type="hidden" name="_token" :value="csrf">
        <input type="hidden" name="_stripe_token" id="stripe-token">
    </form>
  </div>
</template>

<style scoped>
.StripeElement {
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
}
</style>

<script>
var stripeKey = process.env.MIX_STRIPE_PUBLIC_KEY;
var stripe = Stripe(stripeKey, {locale: 'fr'});

var elements = stripe.elements();
var style = {
    base: {
        color: "#32325d",
        lineHeight: '55px'
    }
};

// TODO: set some loading animation - Enable / Disable form based on loading status

export default {
    props: ['clientSecret','url','csrf'],
    data(){
        return {
            card: null,
            loading: false,
            cardErrors: null
        }
    },
    methods: {
        processPayment(){
            this.cardErrors = null;
            this.loading = true;
            console.log('Processing...');
            console.log(stripeKey, this.clientSecret);
            stripe.confirmCardPayment(this.clientSecret, {
                payment_method: {
                    card: this.card
                }
            }).then(result => {
                if (result.error) {
                    this.loading = false;
                    // Show error to your customer (e.g., insufficient funds)
                    this.cardErrors = result.error.message;
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        this.loading = false;
                        console.log('Success')
                        document.getElementById("stripe-token").value = result.paymentIntent.id;
                        // console.log(this, that);
                        this.$refs.stripeRef.submit();
                    }
                }
            });
        }
    },
    mounted: function () {
        this.card = elements.create('card', { hidePostalCode: true, style: style });
        this.card.mount(this.$refs.cardElement);
    }
};
</script>